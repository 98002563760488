import Image from "@components/shared/sub/image";
import { Query } from "@graphql-types";
import { isNotNullOrUndefined } from "@util/assertions";
import { MenuButtonGatsbyLink } from "@util/standard";
import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledPartnerContainer = styled.div`
  #partners-wrapper {
    margin-bottom: 5rem;
  }

  #partners-wrapper .container {
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    display: grid;
  }

  @media screen and (max-width: 600px) {
    #partners-wrapper .container {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 900px) {
    #partners-wrapper .container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 600px) {
    .partners-preview-wrapper {
      margin-bottom: 50px;
    }
  }

  .partners-preview-wrapper {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(33, 33, 33, 0.1);
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .partners-preview {
    cursor: pointer;
    height: 520px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .partners-preview h4 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .partners-preview .gatsby-image-wrapper {
    height: 160px !important;
    width: 160px !important;
    margin: auto;
  }
  .partners-preview .gatsby-image-wrapper img {
    object-fit: contain !important;
  }
  .partners-preview a {
    width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
  }
  .partners-preview p {
    font-size: 14px;
  }

  .read-more-button {
    position: relative;
  }
  .read-more-button a {
    text-align: center;
    min-width: 200px;
    width: auto;
    background-color: #49a9fc;
  }
  .read-more-button a:hover:after {
    content: " →";
  }
  .read-more-button a.other-site:hover:after {
    content: " →";
  }
`;

const CustomersIntro = () => {
  const {
    allSanityPartners: { edges: data },
  } = useStaticQuery<Query>(graphql`
    query allPartnersQuery {
      allSanityPartners {
        edges {
          node {
            _id
            excerpt
            featureImage {
              ...sanityImageWithMeta
            }
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  return (
    <StyledPartnerContainer>
      <div id="partners-wrapper">
        <div className="container">
          {data
            .map(partner => partner.node)
            .filter(isNotNullOrUndefined)
            .map(({ _id, slug, featureImage, title, excerpt }) => {
              return (
                <div className="partners-preview-wrapper" key={_id}>
                  <div className="partners-preview">
                    {slug?.current && (
                      <Link to={slug.current}>
                        {featureImage && <Image data={featureImage} />}
                      </Link>
                    )}
                    <h4>{title}</h4>
                    <p>{excerpt}</p>
                  </div>
                  {slug?.current && (
                    <div className="read-more-button">
                      <MenuButtonGatsbyLink href={slug?.current}>
                        Read More
                      </MenuButtonGatsbyLink>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </StyledPartnerContainer>
  );
};

export default CustomersIntro;
