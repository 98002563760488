import SEO from "@components/shared/seo";
import { Query } from "@graphql-types";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

interface CustomerSeoProps {
  slug: string;
}

const PartnerSEO = ({ slug }: CustomerSeoProps) => {
  const query = useStaticQuery<Query>(graphql`
    query PartnersSEOQuery {
      sanityPartnersPage {
        seo {
          ...sanitySeo
        }
      }
    }
  `);

  const data = query.sanityPartnersPage?.seo;

  return (
    <SEO
      overwriteTitle={data?.pageTitle}
      overwriteDescription={data?.pageDescription}
      seoData={data}
      slug={slug}
    />
  );
};

export default PartnerSEO;
