import React, { useEffect } from "react";

import LayOut from "@components/layout";
import Hero from "@components/shared/hero";
import { Query, SanityPartnersPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { PageProps, graphql } from "gatsby";
import PartersIntro from "../components/pages-components/partners-page/partnersIntro";
import PartnersArray from "../components/pages-components/partners-page/partnersPreview";
import PartnerSEO from "../components/pages-components/partners-page/seo";

interface Data extends Query {
  altRegions: SanityPartnersPageConnection;
}

interface Props extends PageProps<Data, { slug: string }> {}

const PartnersPage = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("partners");
  const partnersPage = props.data.allSanityPartnersPage.nodes[0];

  return (
    <>
      <PartnerSEO slug={props.pageContext.slug} />

      <LayOut>
        <div>
          <Hero singleHeroContent={partnersPage.hero} />
          <PartersIntro data={partnersPage.partnersIntro} />
          <PartnersArray />
        </div>
      </LayOut>
    </>
  );
};

export default PartnersPage;

export const query = graphql`
  query partnersQuery($iban: String) {
    allSanityPartnersPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        partnersIntro {
          partnerTypes {
            _key
            title
            description
            image {
              ...sanityImageWithMeta
            }
          }
        }
      }
    }
    altRegions: allSanityPartnersPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
